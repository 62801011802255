import { Hint, type TextProps } from '@sapphireui/react'

import { getCurrentYear } from '../utils/time'

type CompanyTextProps = TextProps & {
	compact?: boolean
}

const CompanyText = ({ compact = false, ...props }: CompanyTextProps) => (
	<Hint {...props}>
		© {getCurrentYear()}
		{compact ? '' : ', SeeUrCargo'}
	</Hint>
)

export { CompanyText }
