import { Text, type TextProps } from '@sapphireui/react'
import { clsx, type ReactChildren } from '@sapphireui/core/utils'

type TextWithIconProps = TextProps & {
	icon: ReactChildren
}

const TextWithIcon = ({ icon, className, children, ...props }: TextWithIconProps) => (
	<Text
		{...props}
		as="span"
		className={clsx('flex items-center gap-x-3', className)}
	>
		<span className="flex-none text-text-ghost">{icon}</span>
		{children}
	</Text>
)

export { TextWithIcon }
export type { TextWithIconProps }
