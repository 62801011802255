import { clsx, type StyleProps } from '@sapphireui/core/utils'

import { contactsConfig } from '../config/contacts'

import { ContactLink } from './(links)'

const Support = ({ style, className }: StyleProps) => (
	<div
		style={style}
		className={clsx('flex flex-col [&>*]:py-1', className)}
	>
		{[
			contactsConfig.support.items.map((link) => (
				<ContactLink
					key={link.text}
					type={link.type}
					text={link.text}
				/>
			)),
		]}
	</div>
)

export { Support }
