const EmailIcon = () => (
	<svg
		width="1.25em"
		height="1em"
		viewBox="0 0 15 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.4583 0.541687H2.54167C1.75926 0.541687 1.125 1.17595 1.125 1.95835V9.04169C1.125 9.82409 1.75926 10.4584 2.54167 10.4584H12.4583C13.2407 10.4584 13.875 9.82409 13.875 9.04169V1.95835C13.875 1.17595 13.2407 0.541687 12.4583 0.541687Z"
			fill="url(#linear_1)"
		/>
		<path
			d="M1.125 1.95835L7.5 6.20835L13.875 1.95835"
			fill="url(#linear_2)"
		/>
		<path
			d="M13.875 1.95835C13.875 1.17595 13.2407 0.541687 12.4583 0.541687H2.54167C1.75926 0.541687 1.125 1.17595 1.125 1.95835M13.875 1.95835V9.04169C13.875 9.82409 13.2407 10.4584 12.4583 10.4584H2.54167C1.75926 10.4584 1.125 9.82409 1.125 9.04169V1.95835M13.875 1.95835L7.5 6.20835L1.125 1.95835"
			stroke="#161616"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="linear_1"
				x1="7.5"
				y1="0.541687"
				x2="7.5"
				y2="10.4584"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#26A4FF" />
				<stop
					offset="1"
					stopColor="#2683FF"
				/>
			</linearGradient>
			<linearGradient
				id="linear_2"
				x1="7.5"
				y1="0.541687"
				x2="7.5"
				y2="10.4584"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#26A4FF" />
				<stop
					offset="1"
					stopColor="#2683FF"
				/>
			</linearGradient>
		</defs>
	</svg>
)

export { EmailIcon }
