import { memo } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Text, Title, Button } from '@sapphireui/react'

import type { ErrorProps } from './types'

import styles from './styles.module.css'

const ErrorFallback = memo<ErrorProps>(
	({ img, style, title, className, buttonText, description, action, onSubmit }) => (
		<form
			style={style}
			action={action}
			onSubmit={onSubmit}
			className={clsx(
				'relative w-full h-full my-auto flex-center flex-col gap-4',
				className,
			)}
		>
			<div className="relative h-1/3 lg:h-1/2 w-full flex-center select-none">
				<img
					src={img}
					alt={description}
					title={description}
					className="absolute inset-0 w-full h-full"
				/>
				{/* <span className={styles.sphere} /> */}
			</div>

			<div className="flex flex-col items-center gap-y-5 text-center select-none">
				<Title
					as="h1"
					className="text-4xl"
				>
					{title}
				</Title>
				<Text
					disabled
					weight={400}
					className="text-base max-w-sm"
				>
					{description}
				</Text>
				<Button
					gradient
					type="submit"
					className="select-none"
				>
					{buttonText || 'На главную'}
				</Button>
			</div>
		</form>
	),
)

export { ErrorFallback }
