import Link, { type LinkProps as InternalLinkProps } from 'next/link'

import { Icon } from '@seeurcargo/icons'
import { clsx, type StyleProps, type ReactChildren } from '@sapphireui/core/utils'

type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof InternalLinkProps> &
	InternalLinkProps

type TextLinkProps = LinkProps &
	StyleProps & {
		external?: boolean
		underlined?: boolean

		children: ReactChildren
	}

const TextLink = ({
	style,
	children,
	className,
	external = false,
	underlined = false,
	...linkProps
}: TextLinkProps) => (
	<Link
		{...linkProps}
		style={style}
		className={clsx(
			'relative w-max flex flex-row items-center gap-x-2 underline-offset-4 text-text-muted hover:text-primary focus:text-primary transition-all',
			underlined ? 'underline' : '',
			className,
		)}
	>
		{children}
		{external ? (
			<Icon
				size="1em"
				name="arrow-up-right"
				className="absolute top-0 -right-4"
			/>
		) : null}
	</Link>
)

export { TextLink }
export type { TextLinkProps }
