import { clsx, type StyleProps } from '@sapphireui/core/utils'

import { PhoneIcon } from '../../icons/PhoneIcon'
import { EmailIcon } from '../../icons/EmailIcon'

import { TextLink } from './TextLink'

type ContactType = 'email' | 'phone' | 'other'

type ContactLinkProps = StyleProps & {
	text: string

	type?: ContactType
	withIcon?: boolean
}

const ContactLink = ({
	text,
	style,
	className,
	type = 'other',
	withIcon = false,
}: ContactLinkProps) => {
	const { Icon, hrefPrefix } = CONTACT_LINK_BY_TYPE[type]

	const isIconDisplay = withIcon && Icon

	return (
		<TextLink
			title={text}
			style={style}
			aria-label={text}
			href={[hrefPrefix, text].join('').split(' ').join(' ')}
			className={clsx('text-text-ghost', className)}
		>
			{isIconDisplay ? (
				<div className="flex-center w-6 text-lg">
					<Icon />
				</div>
			) : null}
			{text}
		</TextLink>
	)
}

const CONTACT_LINK_BY_TYPE: Record<ContactType, { hrefPrefix: string; Icon?: () => JSX.Element }> =
	{
		phone: {
			hrefPrefix: 'tel:',
			Icon: PhoneIcon,
		},
		email: {
			hrefPrefix: 'mailto:',
			Icon: EmailIcon,
		},
		other: {
			hrefPrefix: '',
		},
	}

export { ContactLink }
export type { ContactLinkProps }
