import type { SvgComponent } from '../types'

const CarIcon: SvgComponent = (props) => (
	<svg
		{...props}
		width="17"
		height="17"
		viewBox="0 0 17 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_2583_328)">
			<path
				d="M14.9818 5.80901C14.793 5.55721 14.4966 5.40901 14.1818 5.40901H12.3636V3.31812C12.3636 2.76583 11.9159 2.31812 11.3636 2.31812H1.54547C0.691579 2.31812 0 3.00969 0 3.86358V11.3636C0 11.9159 0.447715 12.3636 1 12.3636H1.54547C1.54547 13.6425 2.58477 14.6818 3.86365 14.6818C5.14253 14.6818 6.18183 13.6425 6.18183 12.3636H10.8182C10.8182 13.6425 11.8575 14.6818 13.1364 14.6818C14.4153 14.6818 15.4546 13.6425 15.4546 12.3636H16C16.5523 12.3636 17 11.9159 17 11.3636V8.83328C17 8.61691 16.9299 8.40638 16.8 8.23328L14.9818 5.80901ZM3.86365 13.5227C3.22227 13.5227 2.70456 13.0049 2.70456 12.3636C2.70456 11.7222 3.22227 11.2045 3.86365 11.2045C4.50503 11.2045 5.02274 11.7222 5.02274 12.3636C5.02274 13.0049 4.50499 13.5227 3.86365 13.5227ZM13.1364 13.5227C12.495 13.5227 11.9773 13.0049 11.9773 12.3636C11.9773 11.7222 12.495 11.2045 13.1364 11.2045C13.7778 11.2045 14.2955 11.7222 14.2955 12.3636C14.2955 13.0049 13.7777 13.5227 13.1364 13.5227ZM12.3636 8.49995V6.56814H14.2954L15.8139 8.49995H12.3636Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2583_328">
				<rect
					width="17"
					height="17"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
)

export { CarIcon }
