import { Logo } from '../Logo'

import { ErrorFallback } from './ErrorFallback'
import type { ErrorProps } from './types'

import styles from './styles.module.css'

const ErrorPage = (props: ErrorProps) => (
	<div className="px-4 py-10 w-screen h-[100svh] flex flex-col items-center overflow-hidden">
		<div className={styles.banner__bg} />

		<Logo size="lg" />
		<ErrorFallback {...props} />
	</div>
)

export { ErrorPage }
