import { clsx, type StyleProps } from '@sapphireui/core/utils'

import { TextLink } from '../(links)'

type FooterLink = { id: string; href: string; name: string; external?: boolean }

type FooterNavProps = StyleProps & {
	links?: FooterLink[]
}

const FooterNav = ({ links, style, className }: FooterNavProps) => (
	<nav>
		<ul
			style={style}
			className={clsx('flex flex-col gap-y-3', className)}
		>
			{links?.map((link) => (
				<li
					key={link.id}
					className="flex"
				>
					<TextLink
						key={link.id}
						href={link.href}
						external={link.external}
					>
						{link.name}
					</TextLink>
				</li>
			))}
		</ul>
	</nav>
)

export { FooterNav }
