import { Suspense } from 'react'

import { MonitorButtonServer } from './server'
import { MonitorButtonComponent } from './data'

import type { MonitorButtonProps } from './types'

const MonitorButton = (props: MonitorButtonProps) => (
	<Suspense
		fallback={
			<MonitorButtonComponent {...props}>
				Loading status...
			</MonitorButtonComponent>
		}
	>
		<MonitorButtonServer {...props} />
	</Suspense>
)

export { MonitorButton }
export type { MonitorButtonProps }
