import { memo } from 'react'
import { lighten } from 'color2k'

import { clsx } from '@sapphireui/core/utils'
import { Text, type TextProps } from '@sapphireui/react'

type GradientTextProps = Omit<TextProps, 'as' | 'color' | 'gradient'> & {
	brand?: boolean
	color?: string
}

const GradientText = memo<GradientTextProps>(({ brand, color, style, className, ...props }) => (
	<Text
		as="b"
		style={{
			fontWeight: 'inherit',
			...style,
			backgroundImage:
				!brand && color
					? `linear-gradient(to top, ${color}, ${lighten(color, 0.15)})`
					: undefined,
		}}
		className={clsx(
			'text-transparent bg-clip-text',
			brand ? 'bg-gradient-to-t from-gradient-start to-gradient-end' : '',
			className,
		)}
		{...props}
	/>
))

export { GradientText }
export type { GradientTextProps }
