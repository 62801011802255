import { clsx, type ReactChildren } from '@sapphireui/core/utils'
import { Text, Collapse, Responsive, type ResponsiveProps } from '@sapphireui/react'

type FooterSectionProps = Pick<ResponsiveProps, 'style' | 'className' | 'breakpoint'> & {
	label: string
	children?: ReactChildren
}

const FooterSection = ({
	style,
	label,
	children,
	className,
	breakpoint = 'lg',
}: FooterSectionProps) => (
	<Responsive
		as="section"
		style={style}
		className={clsx('flex flex-col', className)}
		mobile={
			<Collapse
				title={label}
				variant="light"
				className="border-b-1.5 border-border rounded-none"
			>
				{children}
			</Collapse>
		}
		desktop={
			<>
				<Text
					as="h2"
					uppercase
					size={12}
					weight={500}
					color="default"
					className="select-none mb-6"
				>
					{label}
				</Text>
				{children}
			</>
		}
		breakpoint={breakpoint}
	/>
)

export { FooterSection }
