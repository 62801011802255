import { memo } from 'react'

import { clsx, type ReactChildren } from '@sapphireui/core/utils'
import { ButtonIcon, type ButtonIconProps } from '@sapphireui/react'

type SocialMediaConfig = {
	id: string
	href: string
	icon: ReactChildren
}

type SocialMediaGroupProps = ButtonIconProps & {
	config: SocialMediaConfig[]
}

const SocialMediaGroup = memo(
	({ config, style, className, ...buttonProps }: SocialMediaGroupProps) => (
		<ul
			style={style}
			className={clsx('flex items-center gap-x-2', className)}
		>
			{config.map(({ id, href, icon }) => (
				<li key={id}>
					<a href={href}>
						<ButtonIcon
							size="xs"
							variant="solid"
							aria-label={id}
							{...buttonProps}
							icon={icon}
						/>
					</a>
				</li>
			))}
		</ul>
	),
)

export { SocialMediaGroup }
export type { SocialMediaConfig, SocialMediaGroupProps }
