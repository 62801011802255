import Link from 'next/link'
import { Button } from '@sapphireui/react'

import { MonitorButtonComponentProps } from './types'

const MonitorButtonComponent = (props: MonitorButtonComponentProps) => {
	return (
		<Link
			target="_blank"
			href="https://status.seeurcargo.com"
		>
			<Button
				auto
				size="xs"
				variant="light"
				{...props}
				className="whitespace-nowrap"
				iconLeft={
					<span className="block w-2.5 h-2.5 rounded-full bg-[currentColor]" />
				}
			/>
		</Link>
	)
}

export { MonitorButtonComponent }
