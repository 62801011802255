import type { Metadata } from 'next'

type SiteConfig = Omit<Metadata, 'alternates'> & {
	alternates: (domainUrl: string | URL) => Metadata['alternates']
}

const siteConfig = {
	title: 'SeeUrCargo • Новое решение отслеживания груза онлайн!',
	abstract: 'SeeUrCargo',
	description:
		'SeeUrCargo — новая платформа, которая позволяет отслеживать и делиться местоположением груза онлайн и в режиме реального времени. Определение местоположения груза не требует установки дополнительного оборудования!',
	keywords: [
		'груз',
		'онлайн',
		'груз',
		'Груз',
		'контакты',
		'панель',
		'управление',
		'панель управления',
		'персональные',
		'Онлайн',
		'Груз',
		'seeurcargo',
		'seecargo',
		'sercargo',
		'seeurcargo.com',
		'seecargo.com',
		'sercargo.com',
		'отслеживание',
		'отслеживание груза',
		'отслеживание груза онлайн',
		'отслеживание онлайн',
		'Отслеживание',
		'Отслеживание онлайн',
		'Отслеживание груза',
		'Отслеживание груза онлайн',
	],

	referrer: 'origin',

	alternates: (url) => ({
		canonical: new URL(url),
		languages: {
			be: new URL(url),
			ru: new URL(url),
			en: new URL(url),
			'ru-RU': new URL(url),
			'ru-by': new URL(url),
			'en-RU': new URL(url),
			'en-by': new URL(url),
		},
	}),

	creator: 'zkharovz',
	authors: [
		{
			name: 'zkharovz',
			url: 'https://github.com/zkharovz',
		},
	],
} satisfies SiteConfig

export { siteConfig }
