const PhoneIcon = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.54167 0.833313H5.375L6.79167 4.37498L5.02083 5.43748C5.77943 6.97565 7.02433 8.22055 8.5625 8.97915L9.625 7.20831L13.1667 8.62498V11.4583C13.1667 11.834 13.0174 12.1944 12.7517 12.46C12.4861 12.7257 12.1257 12.875 11.75 12.875C8.98698 12.7071 6.38094 11.5337 4.42359 9.57639C2.46623 7.61904 1.29291 5.013 1.125 2.24998C1.125 1.87426 1.27426 1.51392 1.53993 1.24825C1.80561 0.982568 2.16594 0.833313 2.54167 0.833313Z"
			fill="url(#linear_1)"
			stroke="url(#linear_2)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="linear_1"
				x1="7.14583"
				y1="0.833313"
				x2="7.14583"
				y2="12.875"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#26A4FF" />
				<stop
					offset="1"
					stopColor="#2683FF"
				/>
			</linearGradient>
		</defs>
	</svg>
)

export { PhoneIcon }
