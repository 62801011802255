type ContactInfo = {
	text: string
	type: 'email' | 'phone'
}

type ContactConfig = {
	id: string
	label: string
	items: ContactInfo[]
}

const contactsConfig = {
	support: {
		id: 'support',
		label: 'Служба поддержки',
		items: [
			{ type: 'phone', text: '+375 44 757 77 71' },
			{ type: 'email', text: 'support@seeurcargo.com' },
		],
	},
	business: {
		id: 'business',
		label: 'Корпоративным клиентам',
		items: [{ type: 'email', text: 'partners@seeurcargo.com' }],
	},
	cooperation: {
		id: 'cooperation',
		label: 'Сотрудничество / Реклама',
		items: [
			{ type: 'email', text: 'offers@seeurcargo.com' },
			{ type: 'email', text: 'marketing@seeurcargo.com' },
		],
	},
} satisfies Record<string, ContactConfig>

export { contactsConfig }
export type { ContactConfig, ContactInfo }
