import type { SvgComponent } from '../types'

const HomeIosIcon: SvgComponent = (props) => (
	<svg
		{...props}
		// width="43"
		// height="47"
		viewBox="0 0 43 47"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill="#363645"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.4232 42.803C34.9145 41.3768 43 32.3826 43 21.5C43 9.62588 33.3741 0 21.5 0C9.62588 0 0 9.62588 0 21.5C0 32.7492 8.63937 41.9806 19.645 42.9211L22 47L24.4232 42.803Z"
		/>
		<g clipPath="url(#clip0_2585_1248)">
			<path
				d="M21.5 23.6199C20.3264 23.6199 19.375 24.5713 19.375 25.7449V29.9949H23.625V25.7449C23.625 24.5713 22.6736 23.6199 21.5 23.6199Z"
				fill="white"
			/>
			<path
				d="M25.0417 25.7448V29.9948H27.875C29.0486 29.9948 30 29.0434 30 27.8698V21.4091C30.0002 21.0411 29.8572 20.6875 29.6012 20.4231L23.5818 13.9156C22.5197 12.7664 20.7271 12.6959 19.578 13.758C19.5234 13.8085 19.4708 13.861 19.4204 13.9156L13.4116 20.421C13.1479 20.6865 12.9999 21.0455 13 21.4197V27.8698C13 29.0434 13.9514 29.9948 15.125 29.9948H17.9583V25.7448C17.9716 23.8133 19.531 22.236 21.4139 22.1906C23.3597 22.1436 25.0268 23.7478 25.0417 25.7448Z"
				fill="white"
			/>
			<path
				d="M21.5 23.6199C20.3264 23.6199 19.375 24.5713 19.375 25.7449V29.9949H23.625V25.7449C23.625 24.5713 22.6736 23.6199 21.5 23.6199Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2585_1248">
				<rect
					width="17"
					height="17"
					fill="white"
					transform="translate(13 13)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export { HomeIosIcon }
