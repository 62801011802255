import { Fragment } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Hint, type TextProps } from '@sapphireui/react'

import { TextLink } from './(links)/TextLink'

type LegalLinkConfig = {
	id: string
	href: string
	name: string
}

type LegalLinksProps = TextProps & {
	links: LegalLinkConfig[]
}

const LegalLinks = ({ links, className, ...props }: LegalLinksProps) => {
	const legalContent = links.map((legal, idx) => (
		<Fragment key={legal.id}>
			<TextLink
				underlined
				href={legal.href}
				className="inline-block px-0"
			>
				{legal.name}
			</TextLink>
			{idx < links.length - 1 ? <span> и </span> : null}
		</Fragment>
	))

	return (
		<Hint
			{...props}
			className={clsx('text-left text-xs leading-6', className)}
		>
			Продолжая использовать сайт, Вы соглашаетесь и использованием файлов куков и
			подтверждаете, что ознакомились с условиями: {legalContent}.
		</Hint>
	)
}

export { LegalLinks }
export type { LegalLinksProps }
