import Image from 'next/image'

import { Hint, Text } from '@sapphireui/react'
import { clsx, ReactChildren, type StyleProps } from '@sapphireui/core/utils'

import { UnderlinedText } from '../(text)'

import styles from './styles.module.css'

type BannerBetaProps = {
	expiredIn?: string
	children?: ReactChildren
}

const BannerBeta = ({ style, className, expiredIn, children }: StyleProps & BannerBetaProps) => (
	<article
		style={style}
		className={clsx(styles.container, className)}
	>
		<div className={styles.bg} />

		<div className={styles.inner}>
			<Image
				alt="inf"
				width={61}
				height={29}
				src="/icons/inf.svg"
			/>

			<Text className={styles.text}>
				Приложение находится в режиме тестирования, у вас{' '}
				<UnderlinedText weight={600}>Безлимитный</UnderlinedText> тариф
			</Text>

			{!children && expiredIn ? (
				<Hint>
					Действителен до{' '}
					<b className="text-text-default">{expiredIn}</b>
				</Hint>
			) : (
				children
			)}
		</div>
	</article>
)

export { BannerBeta }
export type { BannerBetaProps }
