import { memo } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Text, type TextProps } from '@sapphireui/react'

import styles from './styles.module.css'

const EllipsedText = memo<TextProps>(({ style, className, as = 'b', ...props }) => (
	<Text
		{...props}
		as={as}
		style={style}
		className={clsx(styles.ellipsed, className)}
	/>
))

export { EllipsedText }
