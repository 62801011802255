import { unstable_noStore as noStore } from 'next/cache'

import { wait } from '../../utils/promise'

import { MonitorButtonComponent } from './data'
import type { MonitorButtonProps } from './types'

const MonitorButtonServer = async (props: MonitorButtonProps) => {
	const isAllSystemNormal = await getStatus()

	return (
		<MonitorButtonComponent
			{...props}
			color={isAllSystemNormal ? 'info' : 'warning'}
		>
			{isAllSystemNormal ? 'All System normal.' : 'Something is wrong.'}
		</MonitorButtonComponent>
	)
}

const getStatus = async (): Promise<boolean> => {
	noStore()

	await wait(1000)

	const STATUS_PAGE_API_TOKEN = 'Cde13zWtYW9QQJphtw5e4y9L'

	const res = await fetch('https://uptime.betterstack.com/api/v2/monitors', {
		headers: {
			Authorization: `Bearer ${STATUS_PAGE_API_TOKEN}`,
		},
		next: { revalidate: 5 },
	})

	const { data } = (await res.json()) as { data: Array<any> }

	const isAllSystemNormal = data.every((item: any) => item?.attributes?.status === 'up')

	return isAllSystemNormal
}

export { MonitorButtonServer }
