const organizationConfig = {
	requisites: {
		id: 'requisites',
		label: 'Реквизиты',

		unodc: 'УНП 690610438',
		bankInfo: 'р/с BY26BAPB30123631800100000000 в ОАО "Белагропромбанк" код банка BICBAPBBY2X, г. Минск, пр-кт Жукова д. 3.',
		registeredBy:
			'Частное предприятие «Гарташкина и К» Республика Беларусь, 223232 Минская обл., г. Червень ул. Барыкина, д. 106, кв. 12',
	},
} as const

export { organizationConfig }
