import Link from 'next/link'
import { tv, type VariantProps } from 'tailwind-variants'

import type { StyleProps } from '@sapphireui/core/utils'

import { PROJECT_NAME } from '../../env'

import { LogoIcon } from './Logo.Icon'

type LogoProps = StyleProps & {
	asSvg?: boolean

	size?: LogoVariants['size'] | number
}

const Logo = ({ style, className, size = 'md', asSvg = false }: LogoProps) => {
	const enumSize = typeof size !== 'number' ? size : undefined
	const styleSize = typeof size === 'number' ? { ...style, width: size, height: size } : style

	if (asSvg) {
		return (
			<LogoIcon
				boxed
				className={logoVariants({ size: enumSize, className })}
			/>
		)
	}

	return (
		<Link
			href="/"
			style={styleSize}
			aria-label={PROJECT_NAME}
			className={logoVariants({ size: enumSize, className })}
		>
			<LogoIcon
				boxed
				className="w-full h-full"
			/>
		</Link>
	)
}

const logoVariants = tv({
	base: 'relative flex-none cursor-pointer',
	variants: {
		size: {
			xs: 'w-9 h-9',
			sm: 'w-10 h-10',
			md: 'w-11 h-11',
			lg: 'w-12 h-12',
			xl: 'w-14 h-14',
		},
	},
	defaultVariants: {
		size: 'md',
	},
})

type LogoVariants = VariantProps<typeof logoVariants>

export { Logo }
export type { LogoProps }
