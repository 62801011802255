import { RxUpdate } from 'react-icons/rx'
import { PiShareFatFill } from 'react-icons/pi'
import { MdOutlineFeedback } from 'react-icons/md'
import { RiArrowRightUpLine } from 'react-icons/ri'
import { BiDevices, BiSolidDevices } from 'react-icons/bi'
import { FaQ, FaLocationArrow, FaTruckFast } from 'react-icons/fa6'
import { TbShare2, TbSlash, TbInfoSquareRoundedFilled } from 'react-icons/tb'
import { HiOutlineEye, HiOutlineLink, HiOutlineEyeOff, HiArrowNarrowDown, HiHome } from 'react-icons/hi'
import { IoHome, IoMap, IoPerson, IoSettings, IoWallet, IoWarningOutline } from 'react-icons/io5'

import { PiInfo } from 'react-icons/pi'
import { FaMinus } from 'react-icons/fa'
import { IoIosAdd } from 'react-icons/io'

import { RxCross1 } from 'react-icons/rx'
import { BsGeoAltFill } from 'react-icons/bs'
import { BsFillGeoFill } from 'react-icons/bs'
import {
	LuPlus,
	LuMenu,
	LuMapPin,
	LuHistory,
	LuChevronUp,
	LuChevronLeft,
	LuChevronDown,
	LuMoreVertical,
	LuChevronRight,
	LuMoreHorizontal,
	LuTimerReset,
} from 'react-icons/lu'

import { EditIcon } from './custom/EditIcon'
import { TrashIcon } from './custom/TrashIcon'

import { ViberIcon } from './custom/ViberIcon'
import { TelegramIcon } from './custom/TelegramIcon'
import { InstagramIcon } from './custom/InstagramIcon'

import { CarIcon } from './custom/CarIcon'
import { RouteIcon } from './custom/RouteIcon'
import { FinishIcon } from './custom/FinishIcon'
import { HomeIosIcon } from './custom/HomeIosIcon'

import type { SvgComponent } from './types'

const ICONS_BY_NAME = {
	add: IoIosAdd,
	end: BsGeoAltFill,
	pushpins: BsFillGeoFill,
	delete: RxCross1,
	menu: LuMenu,
	minus: FaMinus,
	slash: TbSlash,

	home: IoHome,
	user: IoPerson,
	billing: IoWallet,
	history: LuHistory,
	settings: IoSettings,

	map: IoMap,
	route: RouteIcon,
	'map-pin': LuMapPin,
	location: FaLocationArrow,
	warning: IoWarningOutline,
	timer: LuTimerReset,
	edit: EditIcon,
	share: TbShare2,
	trash: TrashIcon,
	info: TbInfoSquareRoundedFilled,
	infocircle: PiInfo,
	update: RxUpdate,

	device: BiSolidDevices,
	'device-outline': BiDevices,

	faq: FaQ,
	feedback: MdOutlineFeedback,
	'external-link': HiOutlineLink,
	'external-link-solid': PiShareFatFill,

	plus: LuPlus,

	'chevron-up': LuChevronUp,
	'chevron-left': LuChevronLeft,
	'chevron-down': LuChevronDown,
	'chevron-right': LuChevronRight,

	'arrow-down': HiArrowNarrowDown,
	'arrow-up-right': RiArrowRightUpLine,

	'more-y': LuMoreVertical,
	'more-x': LuMoreHorizontal,

	'eye-on': HiOutlineEye,
	'eye-off': HiOutlineEyeOff,
	'route-start': HiHome,

	car: CarIcon,
	truck: FaTruckFast,
	finish: FinishIcon,
	'home-ios': HomeIosIcon,

	viber: ViberIcon,
	telegram: TelegramIcon,
	instagram: InstagramIcon,
} satisfies Record<string, SvgComponent>

type IconName = keyof typeof ICONS_BY_NAME

export { ICONS_BY_NAME }
export type { IconName }
