import type { SvgComponent } from '../types'

const FinishIcon: SvgComponent = (props) => (
	<svg
		{...props}
		// width="43"
		// height="47"
		viewBox="0 0 43 47"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill="white"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24.4232 42.803C34.9145 41.3768 43 32.3826 43 21.5C43 9.62588 33.3741 0 21.5 0C9.62588 0 0 9.62588 0 21.5C0 32.7492 8.63937 41.9806 19.645 42.9211L22 47L24.4232 42.803Z"
		/>
		<g clipPath="url(#clip0_2585_1258)">
			<path
				d="M13.7083 30C13.5205 30 13.3403 29.9254 13.2075 29.7925C13.0746 29.6597 13 29.4795 13 29.2917V15.8333C13 15.0819 13.2985 14.3612 13.8299 13.8299C14.3612 13.2985 15.0819 13 15.8333 13H20.7917C21.5431 13 22.2638 13.2985 22.7951 13.8299C23.3265 14.3612 23.625 15.0819 23.625 15.8333V19.375C23.625 20.1264 23.3265 20.8471 22.7951 21.3785C22.2638 21.9098 21.5431 22.2083 20.7917 22.2083H14.4167V29.2917C14.4167 29.4795 14.342 29.6597 14.2092 29.7925C14.0764 29.9254 13.8962 30 13.7083 30ZM27.1667 15.8333H25.0417V19.375C25.0405 20.5018 24.5924 21.5822 23.7956 22.379C22.9988 23.1757 21.9185 23.6239 20.7917 23.625H20.4772C20.7239 24.0543 21.0792 24.4111 21.5074 24.6597C21.9355 24.9084 22.4215 25.0401 22.9167 25.0417H27.1667C27.9181 25.0417 28.6388 24.7432 29.1701 24.2118C29.7015 23.6804 30 22.9598 30 22.2083V18.6667C30 17.9152 29.7015 17.1946 29.1701 16.6632C28.6388 16.1318 27.9181 15.8333 27.1667 15.8333Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2585_1258">
				<rect
					width="17"
					height="17"
					fill="white"
					transform="translate(13 13)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export { FinishIcon }
