import { memo } from 'react'

import type { SvgProps } from './types'
import { ICONS_BY_NAME, type IconName } from './icons'

const defaultProps: Partial<SvgProps> = {
	focusable: false,
	'aria-hidden': true,
	suppressHydrationWarning: true,
}

type IconBaseProps = Omit<SvgProps, 'width' | 'height'> & {
	size?: string | number
}

type IconProps = IconBaseProps & {
	name: IconName
}

const Icon = memo<IconProps>(({ name, size = 18, ...props }) => {
	const sizeFriendly = typeof size === 'number' ? `${size}px` : size

	const BaseIcon = ICONS_BY_NAME[name]

	return (
		<BaseIcon
			{...defaultProps}
			{...props}
			size={sizeFriendly}
			width={sizeFriendly}
			height={sizeFriendly}
		/>
	)
})

export { Icon }
export type { IconProps, IconBaseProps }
