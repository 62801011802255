import { clsx, type ReactPropsOf } from '@sapphireui/core/utils'

import styles from './styles.module.css'

type ScrollableElements = 'div' | 'article' | 'span' | 'section' | 'aside' | 'header' | 'footer'

type ScrollableProps<T extends ScrollableElements = 'div'> = {
	as?: T
	dir?: 'x' | 'y'
} & ReactPropsOf<T>

const Scrollable = <T extends ScrollableElements>({
	as,
	children,
	className,
	dir = 'y',
	...props
}: ScrollableProps<T>) => {
	const Slot = as || 'div'

	return (
		<Slot
			{...props}
			className={clsx(styles.container, styles[dir], className)}
		>
			{children}
		</Slot>
	)
}

export { Scrollable }
export type { ScrollableProps }
